import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Grid, Typography, Card, CardContent, Container, AppBar, Tabs, Tab, Toolbar, Box, Menu, MenuItem } from '@mui/material';
import Footer from './Footer';
import image1 from './Slide1.JPG';
import image2 from './Slide2.JPG';
import image3 from './slide3.svg';
import image4 from './Slide4.png';


const HowItWorks = () => {
  return (
    <>
      <Container style={{ marginTop: '100px' }}>
        <Box sx={{ justifyContent: 'center' }}>
            <Typography variant="h2" gutterBottom mb={5}>
                How does it work?
            </Typography>
            <Typography variant="h4" gutterBottom my={3}>
                1. Real-time ML Model protection from external malicious adversary using Access Control List (ACL) rules
            </Typography>
            <Typography variant="body1" gutterBottom mb={5}>
                Astraegis protects your internal Resources and Models from external llm prompt injection attacks
            </Typography>
            <Grid container spacing={3} alignItems="center" style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <img src={image1} alt="infographic1" style={{ width: '100%', height: 'auto' }} />
            </Grid>
            <Typography variant="h4" gutterBottom my={5}>
                2. Detect and block attack vectors using Garmr AI ( AI watchdog model specifically trained to detect such vectors)
            </Typography>
            <Typography variant="body1" gutterBottom mb={5}>
                Attack vectors like the one depicted below where your internet facing Customer Service Agents get compromised and in turn compromise your tool Agents, API calling agents, and (in future) possibly your robots
            </Typography>
            <Grid  container spacing={3} justifyContent="center" style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <img src={image2} alt="infographic2" style={{ width: '70%', height: 'auto' }} />
            </Grid>
            <Typography variant="h4" gutterBottom my={5}>
                3. Real-time PII & PHI masking and demasking
            </Typography>
            <Typography variant="body1" gutterBottom mb={5}>
                Astraegis redacts or pseudonymizes your employees' and customers' Personally Identifiable Information (PII), Protected Health Information (PHI) and Payment Card Information (PCI)  data so that it never leaves your organization, thereby allowing you to take advantage of frontier models provided by AI companies while remaining compliant with GDPR, HIPAA or PCI DSS
            </Typography>
            <Grid  container spacing={3} justifyContent="center" style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <img src={image3} alt="infographic2" style={{ width: '70%', height: 'auto' }} />
            </Grid>
            <Typography variant="h4" gutterBottom my={5}>
                4. Multi-agent AI Agent orchestration with AD integration
            </Typography>
            <Typography variant="body1" gutterBottom mb={5}>
                You can easily replicate your existing organizational structure by integrating with Active Directory ( AD) to spawn and onboard multiple long-running Agents that can collaborate with each other and work along side your teams.
            </Typography>
            <Grid  container spacing={3} justifyContent="center" style={{ paddingLeft: '2em', paddingRight: '2em' }}>
            <img src={image4} alt="infographic2" style={{ width: '70%', height: 'auto' }} />
            </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HowItWorks;